/**
 * Rounds given value to `n` decimal places
 * @param {string|number} value - Value to be rounded off
 * @param {number} [places=2] - Decimal places
 * @returns {string} - Percentage
 */
export const numberRound = function (value, places = 2) {
  if (Number.isInteger(value)) {
    return value
  }

  return parseFloat(value).toFixed(places)
}
