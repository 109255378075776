<template>
  <AdminSubMenu
    :sub-menu="finalSubMenu">
    <slot />
  </AdminSubMenu>
</template>

<script>
import AdminSubMenu from '@/views/admin/AdminSubMenu'

export default {
  name: 'MasterSettingsSubMenu',
  components: { AdminSubMenu },
  props: {
    userRole: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      menu: {
        name: 'admin/master-settings'
      },
      subMenu: [
        {
          name: 'admin/master-settings',
          title: 'Book & hold'
        },
        {
          name: 'admin/master-settings-pricing',
          title: 'Pricing'
        }
      ]
    }
  },
  computed: {
    finalSubMenu () {
      if (this.$enableMasterSettingsBookAndHold()) {
        return this.subMenu
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.subMenu.shift()
      return this.subMenu
    }
  }
}
</script>

<style scoped>

</style>
