<template>
  <div class="section admin-submenu-section">
    <div class="container">
      <div class="m-b">
        <router-link
          v-if="menu"
          :to="menu"
          class="has-text-secondary has-text-weight-bold">
          <IconBase
            width="14"
            height="12"
            icon-name="back"
            class="m-r-2x">
            <IconBack />
          </IconBase>
          <span>{{ $t('views.admin.Back') }}</span>
        </router-link>
      </div>
      <div class="columns is-gapless is-align-bottom">
        <div class="column m-r">
          <h1 class="is-size-3 has-text-weight-bold has-text-primary m-b-3x">
            <slot />
          </h1>
        </div>
        <div class="column is-narrow">
          <ul class="admin-submenu">
            <li
              v-for="(r,index) in subMenuList"
              :key="`${r.name}-${index}`"
              class="admin-submenu-item">
              <router-link :to="{ name: r.name, params: {...$route.params, ...r.params} }">
                {{ r.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBack from '@/components/icons/IconBack'

export default {
  name: 'AdminSubMenu',
  components: { IconBack },
  props: {
    menu: {
      type: Object,
      default: null
    },
    subMenu: {
      type: Array,
      required: true
    }
  },
  computed: {
    subMenuList () {
      return this.subMenu.filter((menu) => this.$adminHasRole(menu.role))
    }
  }

}
</script>

<style scoped>

</style>
