<template>
  <path d="M14 5.09927H2.91039L6.93462 1.07505L5.85956 0L0 5.85956L5.85956 11.7191L6.93462 10.6441L2.91039 6.61985H14V5.09927Z" />
</template>

<script>
export default {
  name: 'IconBack'
}
</script>

<style scoped>

</style>
