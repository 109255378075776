<template>
  <div>
    <MasterSettingsSubMenu>Master settings</MasterSettingsSubMenu>
    <div class="section">
      <div class="container">
        <h2 class="is-size-4 has-text-weight-bold has-text-secondary">
          Default Pricing
        </h2>
        <p class="m-b-4x">
          This pricing will be applied only to newly registered users. For existing users, please modify from <router-link
            to="client-management"
            class="has-text-secondary">
            Client Management
          </router-link>.
        </p>
        <el-form
          ref="form"
          :model="form"
          class="columns is-large-gap is-multiline"
          label-position="left"
          status-icon>
          <div class="column is-6 m-b-2x">
            <div class="columns">
              <div class="column">
                <div class="has-text-primary has-text-weight-bold">
                  Forex Markup (Other)
                </div>
                <div>{{ $t('views.admin.forexMarkup.desc') }}</div>
              </div>
              <el-form-item
                class="column is-narrow"
                prop="forexMarkupPct">
                <el-input-number
                  v-model="form.forexMarkupPct"
                  :min="0"
                  :controls="false"
                  class="percentage-input-number" />
              </el-form-item>
            </div>
          </div>
          <div class="column is-6 m-b-2x">
            <div class="columns">
              <div class="column">
                <div class="has-text-primary has-text-weight-bold">
                  Forex Markup (NGN)
                </div>
                <div>{{ $t('views.admin.forexMarkup.desc') }}</div>
              </div>
              <el-form-item
                class="column is-narrow"
                prop="forexMarkupPct">
                <el-input-number
                  v-model="form.forexMarkupPctNgn"
                  :min="0"
                  :controls="false"
                  class="percentage-input-number" />
              </el-form-item>
            </div>
          </div>
          <div class="column is-6 m-b-2x">
            <div class="columns">
              <div class="column">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('views.admin.transactionFee.label') }}
                </div>
                <div>{{ $t('views.admin.transactionFee.desc') }}</div>
              </div>
              <el-form-item
                class="column is-narrow"
                prop="txFeesDefaultPct">
                <el-input-number
                  v-model.trim="form.txFeesDefaultPct"
                  :min="0"
                  :max="100"
                  :controls="false"
                  class="percentage-input-number" />
              </el-form-item>
            </div>
          </div>
          <div class="column is-6 m-b-2x">
            <div class="columns">
              <div class="column">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('views.admin.minMarkup.label') }}
                </div>
                <div>{{ $t('views.admin.minMarkup.desc') }}</div>
              </div>
              <el-form-item
                class="column is-narrow"
                prop="minMarkupInPct">
                <el-input-number
                  v-model.trim="form.minMarkupInPct"
                  :min="0"
                  :max="100"
                  :controls="false"
                  class="percentage-input-number" />
              </el-form-item>
            </div>
          </div>
          <!-- FEE for MORE than 30USD -->
          <div class="column is-6 m-b-2x">
            <div class="columns">
              <div class="column">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('views.admin.lowValueTransactionFee.label') }}
                </div>
                <div>{{ $t('views.admin.lowValueTransactionFee.desc') }}</div>
              </div>
              <el-form-item
                class="column is-narrow"
                prop="minCCFeeBelowUSD">
                <el-input
                  v-model.trim="form.additionalFee"
                  v-number-input.float="{decimal:2}"
                  :min="0"
                  :max="100"
                  :placeholder="0.0"
                  style="width:180px">
                  <template slot="suffix">
                    USD
                  </template>
                </el-input>
              </el-form-item>
            </div>
          </div>
          <div class="column is-12 has-text-left">
            <hr class="m-t-0 m-b-5x">
            <div class="columns is-large-gap">
              <!-- <div class="column is-narrow">
                <div class="has-text-primary has-text-weight-bold">{{ $t('views.admin.Published ver') }}</div>
                <div>{{ id }}</div>
              </div>
              <div class="column is-narrow">
                <div class="has-text-primary has-text-weight-bold ">{{ $t('views.admin.Published at') }}</div>
                <div>{{ createdAt|date('DD MMM YYYY, HH:MM:SS') }}</div>
              </div>
              <div class="column is-narrow">
                <div class="has-text-primary has-text-weight-bold">{{ $t('views.admin.Published by') }}</div>
                <div>{{ editorName }}</div>
              </div> -->
              <div
                class="column is-narrow"
                style="margin-left: auto">
                <el-button
                  :loading="isSubmitting"
                  class="button-secondary"
                  @click="handleSave">
                  {{ $t('views.admin.Publish') }}
                </el-button>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { numberRound } from '@/plugins/number'
import MasterSettingsSubMenu from '@/views/admin/MasterSettingsSubMenu'

export default {
  name: 'MasterSettingsPricing',
  components: {
    MasterSettingsSubMenu
  },
  data () {
    return {
      isSubmitting: false,
      pricing: undefined,
      form: {
        txFeesDefaultPct: undefined,
        minMarkupInPct: undefined,
        forexMarkupPct: undefined,
        forexMarkupPctNgn: undefined
      }
    }
  },
  computed: {
    id () {
      return this.pricing ? this.pricing.id : '-'
    },
    createdAt () {
      return this.pricing ? this.pricing.createdAt : ''
    },
    editorName () {
      return this.pricing ? this.pricing.editor.displayName : '-'
    }
  },
  watch: {
    '$route' () {
      this.fetchData()
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    handleSave () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.commit('SHOW_FULLSCREEN_LOADER', 'Saving')
          this.isSubmitting = true
          const payload = {
            minMarkupInPct: this.form.minMarkupInPct / 100,
            forexMarkup: this.form.forexMarkupPct / 100,
            forexMarkupNgn: this.form.forexMarkupPctNgn / 100,
            txFee: this.form.txFeesDefaultPct / 100,
            additionalFee: { value: this.form.additionalFee * 100, currency: 'USD' },
            name: 'pricing'
          }
          this.$store.dispatch('admin/updateMasterSettings', payload).then(({ outlets }) => {
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
            this.isSubmitting = false
            const { data } = outlets.details
            this.form = {
              txFeesDefaultPct: numberRound(data.txFee * 100),
              minMarkupInPct: numberRound(data.minMarkupInPct * 100),
              forexMarkupPct: numberRound(data.forexMarkup * 100),
              forexMarkupPctNgn: numberRound(data.forexMarkupNgn * 100),
              additionalFee: numberRound(data.additionalFee.value / 100)
            }
            this.$message({
              message: 'Default pricing have been saved.',
              type: 'success'
            })
          }).catch((err) => {
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
            this.isSubmitting = false
            this.$refs.form.resetFields()
            this.$message({
              message: err.message || (err.error && err.error.message) || '',
              type: 'error'
            })
          })
        }
      })
    },
    fetchData () {
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      this.$store.dispatch('admin/getMasterSettings', { name: 'pricing' }).then(({ outlets }) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        const { data } = outlets.details[0]
        this.form = {
          txFeesDefaultPct: numberRound(data.txFee * 100),
          minMarkupInPct: numberRound(data.minMarkupInPct * 100),
          forexMarkupPct: numberRound(data.forexMarkup * 100),
          forexMarkupPctNgn: numberRound(data.forexMarkupNgn * 100),
          additionalFee: numberRound(data.additionalFee.value / 100)
        }
      }).catch((err) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    }
  },
  metaInfo () {
    return {
      title: 'Master Settings - Pricing'
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .el-input--suffix .el-input__inner {
    padding-right: 90px;
  }
</style>
