<template>
  <div>
    <MasterSettingsSubMenu>Master settings</MasterSettingsSubMenu>
    <div class="section">
      <div class="container">
        <div class="columns is-large-gap">
          <div class="column is-6">
            <h2 class="is-size-4 has-text-weight-bold has-text-secondary m-b-3x">
              Book &amp; Hold
            </h2>
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-width="360px"
              label-position="left"
              hide-required-asterisk
              status-icon
              @submit.native.prevent="handleSave">
              <!-- <el-form-item
                prop="autoCancelDueDate">
                <template slot="label">
                  <p style="line-height: 1.4;"><strong>Auto-cancel</strong><br>
                    How many days before free cancellation deadline</p>
                </template>
                <el-input
                  v-model.number="form.autoCancel">
                  <template slot="suffix">days before</template>
                </el-input>
              </el-form-item>
              <hr> -->
              <el-form-item
                prop="firstPaymentReminderDueDate">
                <template slot="label">
                  <p style="line-height: 1.4;">
                    <strong>1st payment reminder email</strong><br>
                    How many days before auto-cancel
                  </p>
                </template>
                <el-input
                  v-model.number="form.firstPaymentReminder">
                  <template slot="suffix">
                    days before
                  </template>
                </el-input>
              </el-form-item>
              <div style="height: 10px;" />
              <el-form-item
                prop="secondaryPaymentReminderDueDate">
                <template slot="label">
                  <p style="line-height: 1.4;">
                    <strong>2nd payment reminder email</strong><br>
                    How many days before auto-cancel
                  </p>
                </template>
                <el-input
                  v-model.number="form.secondPaymentReminder">
                  <template slot="suffix">
                    days before
                  </template>
                </el-input>
              </el-form-item>
              <div>
                <el-button
                  :loading="isSubmitting"
                  class="button-secondary m-r-3x"
                  style="min-width: 155px;"
                  native-type="submit">
                  Save
                </el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MasterSettingsSubMenu from '@/views/admin/MasterSettingsSubMenu'

export default {
  name: 'MasterSettings',
  components: {
    MasterSettingsSubMenu
  },
  data () {
    return {
      isSubmitting: false,
      form: {
        autoCancel: undefined,
        firstPaymentReminder: undefined,
        secondaryPaymentReminder: undefined
      },
      rules: {
        autoCancel: [
          {
            required: true,
            message: 'Auto-cancel is required.',
            trigger: [
              'change', 'blur'
            ]
          },
          {
            type: 'number',
            message: 'Auto-cancel must be a numeric value.'
          }
        ],
        firstPaymentReminder: [
          {
            required: true,
            message: '1st payment reminder email is required.',
            trigger: [
              'change', 'blur'
            ]
          },
          {
            type: 'number',
            message: '1st payment reminder email must be a numeric value.'
          }
        ],
        secondPaymentReminder: [
          {
            required: true,
            message: '2nd payment reminder email is required.',
            trigger: [
              'change', 'blur'
            ]
          },
          {
            type: 'number',
            message: '2nd payment reminder email must be a numeric value.'
          }
        ]
      }
    }
  },
  watch: {
    '$route' () {
      this.fetchData()
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    handleSave () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.commit('SHOW_FULLSCREEN_LOADER', 'Saving')
          this.isSubmitting = true
          this.$store.dispatch('admin/updateMasterSettings', { name: 'paylater', ...(this.form) }).then(({ outlets }) => {
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
            this.isSubmitting = false
            const { autoCancel, firstPaymentReminder, secondPaymentReminder } = outlets.details.data
            this.form = {
              autoCancel,
              firstPaymentReminder,
              secondPaymentReminder
            }
            this.$message({
              message: 'Master settings have been saved.',
              type: 'success'
            })
          }).catch((err) => {
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
            this.isSubmitting = false
            this.$refs.form.resetFields()
            this.$message({
              message: err.message || (err.error && err.error.message) || '',
              type: 'error'
            })
          })
        }
      })
    },
    fetchData () {
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      this.$store.dispatch('admin/getMasterSettings', { name: 'paylater' }).then(({ outlets }) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        const { autoCancel, firstPaymentReminder, secondPaymentReminder } = outlets.details[0].data
        this.form = {
          autoCancel,
          firstPaymentReminder,
          secondPaymentReminder
        }
      }).catch((err) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    }
  },
  metaInfo () {
    return {
      title: 'Master Settings - Booking & hold'
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .el-input--suffix .el-input__inner {
    padding-right: 90px;
  }
</style>
